import compact from 'lodash/compact'

const stringMatches = (rawVal: string = '', rawQuery: string = '') => {
  const query = rawQuery.trim().toLowerCase()

  if (!query) return true

  const val = rawVal.trim().toLowerCase()

  if (!val) return false

  const tokens = compact(query.split(' ').map((str) => str.trim()))

  for (const token of tokens) {
    if (!val.includes(token)) return false
  }

  return true
}

export default stringMatches
