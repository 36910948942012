export const translations = {
  accountMenu: 'Account menu',
  backToClassicToastPayroll: 'Back to classic Toast Payroll',
  badgeNew: 'NEW',
  clearEmployeeSearch: 'Clear employee search',
  closeAppMenu: 'Close app menu',
  closeGlobalMenu: 'Close global menu',
  expandAppMenu: 'Expand app menu',
  giveMeATour: 'Give me a tour',
  globalMenu: 'Global menu',
  helpMenu: 'Help menu',
  navigationError: 'Navigation Error',
  navV2DeprecationWarning:
    'Classic Toast Payroll navigation will no longer be available as of',
  navV2DeprecationWarningShort: 'Classic Toast Payroll will not be available',
  navV2DeprecationDate: 'October 1',
  payrollNavigationErrorMessage:
    'There was an issue loading Payroll navigation items',
  payrollOnboardingChecklist: 'Payroll onboarding checklist',
  previousMenu: 'Previous menu',
  searchEmployees: 'Search employees',
  somethingWentWrong: 'Something went wrong',
  staffToolsMenu: 'Staff tools menu',
  switch: 'Switch',
  switchToNewLayout: 'Switch to New Layout',
  tryAgainLater: 'Try again later',
  tryNewToastPayroll: 'Try the new Toast Payroll',
  tryItNow: 'Try it now',
  customerSwitcherErrorState: 'We are looking into it...',
  customerSwitcherBlankStateText: 'We could not find any matches for',
  customerSwitcherBlankStateCTA:
    'Try again using a different spelling or keywords',
  customerSwitcherSearchPlaceholder: 'Search restaurant',
  session: {
    userChanged: {
      heading: `Welcome {{name}}!`,
      body: 'You are now logged in since you logged into Toast.',
      logOutButton: 'Log out',
      nextButton: 'Continue'
    },
    loggedOut: {
      heading: "You've been logged out",
      body: 'Please log back in to continue.',
      nextButton: 'Back to login'
    }
  }
}

type Translations = typeof translations

export type { Translations }
