import { useCallback } from 'react'
import { useBanquetProps } from 'banquet-runtime-modules'

type EcNavigate = (url: string) => void

const useEcNavigate = () => {
  const props = useBanquetProps()

  // @ts-ignore
  const propsNavigate = props.ec?.ecNavigate as EcNavigate | undefined

  const ecNavigate = useCallback(
    (url: string) => {
      if (propsNavigate) {
        propsNavigate(url)
      } else {
        window.location.href = url
      }
    },
    [propsNavigate]
  )

  return ecNavigate
}

export default useEcNavigate
