import * as React from 'react'
import { useMemo } from 'react'
import { AppErrorBoundary } from '../AppErrorBoundary'

type AppContainerProps = {
  children: React.ReactNode
  application: string
  testId: string
  tailwindVersion: string
}

type AppContainerConfig = {
  containerId?: string
  application?: string
  containerSelector?: () => HTMLElement
}

export const AppContainerContext =
  React.createContext<AppContainerConfig | null>(null)

type Props = AppContainerProps & JSX.IntrinsicElements['div']

export const AppContainer = (props: Props) => {
  const { children, application, testId, tailwindVersion } = props

  const twProps = { [tailwindVersion]: true }

  const value = useMemo(() => {
    const containerSelector = () => document.getElementById(testId)!
    return { containerId: testId, containerSelector, application }
  }, [testId, application])

  return (
    <div id={testId} data-testid={testId} {...twProps}>
      <AppContainerContext.Provider value={value}>
        <div data-app-container>
          <AppErrorBoundary application={application}>
            {children}
          </AppErrorBoundary>
        </div>
      </AppContainerContext.Provider>
    </div>
  )
}

export const useAppContainerContext = () =>
  React.useContext(AppContainerContext) || {}
