import { apiCall } from '../helpers'

import { useMutation } from '@tanstack/react-query'

type Variables = {
  language: string
}

const useChangeLanguageMutation = () => {
  return useMutation({
    mutationFn: async (variables: Variables) => {
      const { language } = variables

      const resp = await apiCall({
        method: 'POST',
        url: `/CompanyCode/ChangeLanguage`,
        body: { language: language }
      })

      return resp
    }
  })
}

export default useChangeLanguageMutation
