import type { TranslationLoader } from '@toasttab/buffet-pui-translation-utilities'
import { translations as enUS } from './en-US'
import type { Translations } from './en-US'

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms))

const translationLoader: TranslationLoader = async (language) => {
  // todo: remove when actually implementing
  if (process.env.NODE_ENV !== 'production') {
    console.log(`Loading language = '${language}'`)
    await wait(1000)
  }

  const fallback = { translations: enUS }
  // Consider using import, as in this example, as it enables code splitting:
  let local
  if (language !== 'en-US') {
    switch (language) {
      case 'es':
        local = await import(/* webpackChunkName: "es" */ './es')
        break
      default:
        local = undefined
    }
  }
  return { local, fallback }
}

export type { Translations }

export { translationLoader }
