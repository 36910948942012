import * as React from 'react'
import { useQuery } from 'react-query'

import { useUser } from '@toasttab/ec-session'

import { hasAnyRole } from '@local/ec-app'

import { useDebounceValue } from '../../../v3/hooks'
import { useNavigationService } from '../../../v2/services'
import { reconstructEmployeeQuickSearchLink } from './reconstructEmployeeLink'

export const useHasQuickSearchAccess = () => {
  const user = useUser()
  return hasAnyRole(user, [
    'HR_Executive',
    'HR_Plus',
    'Manager',
    'Payroll',
    '_SuperAdmin',
    '_SuperAdminLight'
  ])
}

const QuickSearchServiceContext = React.createContext()

const useQuickSearchService = () => {
  return React.useContext(QuickSearchServiceContext)
}

const DEFAULT_BREADCRUMBS = []
const DEFAULT_RESULTS = []

const QuickSearchService = ({ children }) => {
  const { breadcrumbs = DEFAULT_BREADCRUMBS } = useNavigationService()
  const user = useUser()
  const [rawQuery, setQuery] = React.useState('')
  const [enabled, setEnabled] = React.useState(false)

  const query = useDebounceValue(rawQuery, 500)

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ search: query }),
    credentials: 'same-origin'
  }

  //When a user is on the contextual page for a team, we want the urls for the quick search to be the current url, but we do not want that to happen on the team dashboard
  const shouldReplaceEmployeeLink = !!(
    breadcrumbs.length > 1 && breadcrumbs[0].title === 'Team'
  )

  const url = `/mvc/${user.client}/Dashboard/Root/employeeQuickSearch`

  const fetchEmployees = async () => {
    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const json = await response.json()
    json.obj.pop()
    return {
      ...json,
      obj: json.obj.map((employee) => {
        let link = employee.route

        if (shouldReplaceEmployeeLink) {
          link = reconstructEmployeeQuickSearchLink({
            link: employee.route,
            pathname: window.location.pathname
          })
        }
        return {
          id: employee.value,
          name: employee.caption,
          link
        }
      })
    }
  }

  const { data, isSuccess } = useQuery(['employees', query], fetchEmployees, {
    enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10 * 60 * 1000,
    onError: (error) => {
      console.error(error.message)
    }
  })

  // results is used in a context and so must be referentially stable
  const results = isSuccess ? data.obj : DEFAULT_RESULTS

  const value = React.useMemo(() => {
    return [results, setQuery, setEnabled]
  }, [results, setQuery, setEnabled])

  return (
    <QuickSearchServiceContext.Provider value={value}>
      {children}
    </QuickSearchServiceContext.Provider>
  )
}

export { QuickSearchService, useQuickSearchService }
