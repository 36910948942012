import * as React from 'react'
import { useEcProps } from '@toasttab/ec-banquet-props'
import { createTranslationUtilities } from '@toasttab/buffet-pui-translation-utilities'

import type { Translations } from './locales/translationLoader'
import { translationLoader } from './locales/translationLoader'

const {
  TranslationProvider, //
  useTranslation
} = createTranslationUtilities<Translations>({
  spaName: 'ec-navigation-spa',
  translationLoader,
  disableLoadingElement: true
})

type Props = {
  children: React.ReactNode
}

const I18nProvider = (props: Props) => {
  const { children } = props

  const { i18n } = useEcProps()

  return <TranslationProvider i18n={i18n}>{children}</TranslationProvider>
}

export { I18nProvider, useTranslation }
