import * as React from 'react'
import { NavigationServiceContext } from './NavigationService'
import FinalizeMenus from './FinalizeMenus'
import { useApi } from '@local/ec-navigation-spa/src/v2/shared/hooks/useApi'
import { useUser } from '@toasttab/ec-session'

export const AsyncNavigationService = ({ url, menus, links, children }) => {
  const user = useUser()

  const cached = sessionStorage.getItem(`${user.client}:navigation`)
  let initalValue = { isReady: false }

  if (!!cached) {
    initalValue = JSON.parse(cached)
  }

  const [value, setValue] = React.useState(initalValue)

  useApi(url.replace(/&amp;/, '&'), (data) => {
    const { breadcrumbMenu, applicationMenus, contextualMenus } = FinalizeMenus(
      data.obj.breadcrumbs,
      data.obj.applicationMenus,
      data.obj.contextualMenus
    )

    if (window.Toast?.navigationCompleteCallback) {
      window.Toast.navigationCompleteCallback()
    }

    const result = {
      applicationMenus: applicationMenus,
      contextualMenus: contextualMenus,
      breadcrumbs: breadcrumbMenu,
      isReady: true,
      menus,
      links,
      jumpToMenus: data.obj.jumpToMenus
    }

    window.Toast.navigationData = result

    sessionStorage.setItem(`${user.client}:navigation`, JSON.stringify(result))

    setValue(result)
  })

  return (
    <NavigationServiceContext.Provider value={value}>
      {children}
      {value.isReady && <span data-testid='navigation-loaded'></span>}
    </NavigationServiceContext.Provider>
  )
}
