import * as React from 'react'

import { useUser } from '@toasttab/ec-session'

import { useConfig, useEnvironment } from '../../hooks'
import { useWindowProvider } from '../window'

import { initializePendo } from './pendoInitializer'

export const TrackingProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const window = useWindowProvider()
  const user = useUser()
  const { pendoClientKey } = useConfig()
  const { isLocal } = useEnvironment()

  if (isLocal) {
    console.info('skipping pendo initialization in local development')
    return <>{children}</>
  }

  if (!window.isPendoSetupComplete) {
    window.isPendoSetupComplete = true
    initializePendo(pendoClientKey, user, window)
  }

  return <>{children}</>
}
