import * as React from 'react'
import { useMemo } from 'react'
import { useNavigationService } from '@local/ec-navigation-spa/src/v2/services'

const ServiceContext = React.createContext()

const useService = () => React.useContext(ServiceContext)

const flatten = (array, all) => {
  array.forEach((m) => {
    recursiveAdd(m, all)
  })
}

const recursiveAdd = ({ title, link, subMenus = [] }, all) => {
  flatten(subMenus, all)
  all.push({ title, link })
}

const renderData = ({ jumpToMenus, applicationMenus, menus }) => {
  let value = []
  let isReady = true

  try {
    const all = []

    flatten(applicationMenus, all)
    flatten(jumpToMenus, all)

    value = [
      ...all,
      menus.account,
      menus.contact,
      menus.documentCourier,
      menus.help
    ]
  } catch (ex) {
    console.error('unable to generate jump to menu modal', ex, {
      jumpToMenus,
      applicationMenus,
      menus
    })
    isReady = false
  }
  return [value, isReady]
}

const Service = ({ children }) => {
  const { jumpToMenus, applicationMenus, menus } = useNavigationService()

  const value = useMemo(() => {
    return renderData({ jumpToMenus, applicationMenus, menus })
  }, [jumpToMenus, applicationMenus, menus])

  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  )
}

export { Service as JumpToMenuService, useService as useJumpToMenuService }
