import { useChangeLanguageMutation } from '../data'
import { useTranslation } from '@local/ec-app/src/AppContainer/contexts/i18n'

type Language = 'es' | 'en-US'

const useChangeLanguage = () => {
  const { language, changeLanguage: i18nChangeLanguage } = useTranslation()

  const mutation = useChangeLanguageMutation()

  const changeLanguage = async (language: Language) => {
    await mutation.mutateAsync({ language })

    // buffet uses 'es-US' for Locale but EC uses 'es'
    // @ts-ignore
    i18nChangeLanguage(language)
  }

  return { language: language, changeLanguage }
}

export default useChangeLanguage
