import { useUser } from '@toasttab/ec-session'
import { useEffect, useReducer } from 'react'

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return { isReady: true, results: action.payload }
    case 'ERROR':
      console.error(action.payload)
      return {
        isReady: true,
        results: [],
        isErrored: true,
        error: action.payload
      }
    default:
      return state
  }
}

export function useApi(url, normalize, options = {}, deps = []) {
  const user = useUser()
  const [state, dispatch] = useReducer(reducer, {
    isReady: false,
    results: [],
    isErrored: false,
    error: null
  })

  options = {
    ...options,
    credentials: 'same-origin'
  }

  url = url.replace(':client', user.client)
  useEffect(() => {
    let didCancel = false
    const go = async () => {
      const response = await fetch(url, options).catch((ex) => {
        dispatch({ type: 'ERROR', payload: ex })
      })

      if (didCancel) return

      if (response.status === 500) {
        dispatch({ type: 'ERROR', payload: response.statusText })
      } else {
        const json = await response.json().catch((ex) => {
          dispatch({ type: 'ERROR', payload: ex })
        })

        if (normalize) {
          dispatch({ type: 'LOAD', payload: normalize(json) })
        } else {
          dispatch({ type: 'LOAD', payload: json })
        }
      }
    }

    go()

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
  return [state.results, state.isReady]
}
