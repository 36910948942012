import { makeEcSpaCompanyLocalStorageState } from '@toasttab/ec-storage'
import type { Configuration } from '@toasttab/ec-storage'
import every from 'lodash/every'
import random from 'lodash/random'
import isFinite from 'lodash/isFinite'
import { NavItem, NavSection } from '../../types'

const isNavItem = (item: any): item is NavItem => {
  return !!(typeof item?.path === 'string')
}

/*
NOTE: Never _change_ the type of a LS value once it's been released to the wild
instead, create a new key with your new type
*/

type PayrollNavigation = NavSection[]

type LastJumpColor = {
  name: string
  hue: number
}

type LocalStorageTypes = {
  'last-payroll-navigation-sections': PayrollNavigation
  'last-payroll-global-navigation-sections': NavSection[]
  'last-jump-menu-color': LastJumpColor
}

const CONFIGURATION: Configuration<LocalStorageTypes> = {
  'last-payroll-navigation-sections': {
    default: [],
    isValid: (value: any) => {
      return every(value, (section) => every(section.items, isNavItem))
    }
  },
  'last-payroll-global-navigation-sections': {
    default: [],
    isValid: (value: any) => {
      return every(value.items, isNavItem)
    }
  },
  'last-jump-menu-color': {
    default: { name: 'slate', hue: random(0, 359) },
    isValid: (value: any) => {
      const { name, hue } = value
      return (
        typeof name === 'string' && isFinite(hue) && hue >= -1 && hue <= 360
      )
    }
  }
}

const ecNavCompanyLocalStorage =
  makeEcSpaCompanyLocalStorageState<LocalStorageTypes>({
    spaName: 'ec-navigation-spa',
    configuration: CONFIGURATION
  })

const useEcNavCompanyLocalStorageState =
  ecNavCompanyLocalStorage.useEcSpaCompanyLocalStorageState

export { useEcNavCompanyLocalStorageState }
