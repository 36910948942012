import map from 'lodash/map'
import compact from 'lodash/compact'
import { apiCall } from '../helpers'
import { useQuery } from '@tanstack/react-query'
import sortBy from 'lodash/sortBy'

const SECONDS = 1000

const MINUTES = 60 * SECONDS

type ObjEmployee = {
  caption: string
  route: string
  value: string
}

type Resp = {
  obj: ObjEmployee[]
}

const parseCaption = (caption: string) => {
  const parts = compact(map(caption.split(' - '), (str = '') => str.trim()))

  if (parts.length === 2) {
    return { name: parts[0], employeeNumber: parts[1] }
  }

  return {
    name: caption,
    employeeNumber: null
  }
}

const parseName = (name: string) => {
  if (!name) {
    return { firstName: '', lastName: '' }
  }

  const [last, first] = name.split(',').map((str = '') => str.trim())

  if (last && first) {
    return { firstName: first, lastName: last }
  }
  return { firstName: name, lastName: '' }
}

const parseEmployee = (objEmployee: ObjEmployee) => {
  const { caption, route, value } = objEmployee

  const { name, employeeNumber } = parseCaption(caption)

  const { firstName, lastName } = parseName(name)

  return { firstName, lastName, employeeNumber, route, value }
}

const parseEmployees = (objEmployees: ObjEmployee[]) => {
  const parsed = map(objEmployees, (item) => {
    if (item.route) {
      return parseEmployee(item)
    }
  })
  return compact(parsed)
}

type Args = {
  companyCode: string
  search: string
}

const isNumber = (str: string) => isFinite(parseInt(str, 10))

const useEmployeeQuickSearchQuery = (args: Args) => {
  const { companyCode, search } = args

  // in order to enable (mostly staff) users to view the full employee list in order by employee number,
  // we submit the trimmed search string, but key the query off of the raw search string.
  //
  // this means a search query of of a space character will be a valid and enabled react query state,
  // but will be submitted to the api as an empty string, which returns the full set,
  // and allows us to sort accordingly

  return useQuery({
    queryFn: async () => {
      const resp = await apiCall<Resp>({
        method: 'POST',
        url: `/mvc/${companyCode}/Dashboard/Root/employeeQuickSearch`,
        body: { search: search.trim() }
      })

      const employees = parseEmployees(resp.obj)

      if (isNumber(search) || !search.trim()) {
        //if searching numbers, sort numerically
        return sortBy(employees, (employee) => {
          const { employeeNumber } = employee
          if (employeeNumber) {
            return parseInt(employeeNumber, 10)
          }
        })
      } else {
        //if searching by name, return in alphabetical order
        return sortBy(employees, (employee) => {
          const { firstName, lastName } = employee
          return `${firstName}${lastName}`
        })
      }
    },

    staleTime: 10 * MINUTES,

    cacheTime: 20 * MINUTES,

    queryKey: ['companies', companyCode, 'quick-search', { search }],

    enabled: !!(search && companyCode)
  })
}

export default useEmployeeQuickSearchQuery
