import * as React from 'react'
import { BaseApp } from './BaseApp'
import { withEcApplication } from '@local/ec-app'

export const NavigationRoot = withEcApplication(
  BaseApp,
  'ec-navigation',
  <div></div>,
  'data-nav'
)
