import { useUser } from '@toasttab/ec-session'

export function useIsUserInternal() {
  try {
    return useUser().userType === 'toast_internal'
  } catch (ex) {
    console.error(
      'unable to determine user type, falling back to be less permissive',
      ex
    )

    return false
  }
}
