import * as React from 'react'
import { AsyncNavigationService } from './AsyncNavigationService'
import { StaticNavigationService } from './StaticNavigationService'

const NavigationServiceContext = React.createContext()

const useNavigationService = () => {
  return React.useContext(NavigationServiceContext)
}

const NavigationService = ({ children, data, url, menus, links, refresh }) => {
  const [nonce, tick] = React.useState(false)

  const hasStaticData = !!data

  if (!url) {
    url = refresh().navigationUrl
  }

  if (hasStaticData) {
    return (
      <StaticNavigationService data={data} menus={menus} links={links}>
        {children}
      </StaticNavigationService>
    )
  }

  if (!url) {
    setTimeout(() => {
      tick(!nonce)
      console.info('waiting on navigationUrl to be available')
    }, 200)
    return null
  }

  return (
    <AsyncNavigationService url={url} menus={menus} links={links}>
      {children}
    </AsyncNavigationService>
  )
}

export { NavigationService, useNavigationService, NavigationServiceContext }
