import * as React from 'react'
import { Grommet } from 'grommet'

const ThemeServiceContext = React.createContext()

const useThemeService = () => {
  return React.useContext(ThemeServiceContext)
}

const ThemeService = ({ children }) => {
  return (
    <ThemeServiceContext.Provider value={theme}>
      <Grommet theme={theme}>{children}</Grommet>
    </ThemeServiceContext.Provider>
  )
}

export { ThemeService, useThemeService }

const theme = {
  anchor: {
    fontWeight: 400,
    hover: {
      textDecoration: 'none'
    }
  },
  global: {
    font: {
      size: '16px'
    },
    drop: {
      border: { radius: '4px' },
      zIndex: '150'
    },
    layer: {
      zIndex: '150'
    },
    active: {
      background: { color: 'rgba(0,0,0,0.12)' }
    },
    focus: {
      background: { color: 'rgba(0,0,0,0.12)' }
    },
    hover: {
      background: { color: 'rgba(0,0,0,0.12)' }
    },
    colors: {
      icon: {
        dark: '#f8f8f8',
        light: '#666666'
      },
      active: '#ff7f01',
      black: 'rgba(0,0,0,0.87)',
      border: { dark: 'rgba(255,255,255,0.33)', light: 'rgba(0,0,0,0.33)' },
      brand: '#2f2f2f',
      control: { dark: 'accent-1', light: 'brand' },
      focus: '#ff7f01',
      placeholder: '#AAAAAA',
      hover: 'rgba(0,0,0,0.12)',
      selected: 'active',
      text: { dark: 'white', light: 'black' },

      'toast-regular': '#ff7f01',
      'curious-blue': '#2583E3',
      'royal-family': '#1D68B5',
      denim: 'ff7f01',
      'clear-skies': '#7CB4EE',
      white: 'rgba(255,255,255,1)',

      'text-default': 'rgba(0,0,0,0.87)',
      'text-secondary': 'rgba(0,0,0,0.56)',
      'text-disabled': 'rgba(0,0,0,0.30)'
    }
  }
}

export { theme }
