import RouteRecognizer from 'route-recognizer'

const router = new RouteRecognizer()

const replaceMvcUrls = [
  '/mvc/:client/Team/PersonalProfile/ProfileOverview/:employee_id',
  '/mvc/:client/Team/PersonalProfile/CustomAttributes/:employee_id',
  '/mvc/:client/Team/PersonalProfile/EmployeeContact/:employee_id',
  '/mvc/:client/Team/PersonalProfile/OOOffice/:employee_id',
  '/mvc/:client/Team/PersonalProfile/Security/:employee_id',
  '/mvc/:client/Team/CareerProfile/IncidentReporting/:employee_id',
  '/mvc/:client/Team/Performance/Overview/:employee_id',
  '/mvc/:client/Team/CareerProfile/Position/:employee_id',
  '/mvc/:client/Team/CareerProfile/Dashboard/:employee_id',
  '/mvc/:client/Team/Performance/ProgressiveDiscipline/:employee_id',
  '/mvc/:client/Team/Performance/QuickNotes/:employee_id',
  '/mvc/:client/Team/CareerProfile/Rating/:employee_id',
  '/mvc/:client/Team/StatusChanges/StatusChange/:employee_id',
  '/mvc/:client/Team/CareerProfile/Training/:employee_id',
  '/mvc/:client/Team/Benefits/FilingData/:employee_id',
  '/mvc/:client/Team/Benefits/BenefitsSummary/:employee_id',
  '/mvc/:client/Team/Pay/DirectDeposits/:employee_id',
  '/mvc/:client/Team/Pay/GLAllocation/:employee_id',
  '/mvc/:client/Team/Pay/PayChange/:employee_id',
  '/mvc/:client/Team/Pay/PayHistory/:employee_id',
  '/mvc/:client/Team/Pay/PayInformation/:employee_id',
  '/mvc/:client/Team/Pay/TotalCompensation/:employee_id',
  '/mvc/:client/Team/TimeAndLabor/TimeAwayFromWork/:employee_id',
  '/mvc/:client/Team/TimeAndLabor/Timesheets/:employee_id',
  '/mvc/:client/Team/CareerProfile/Asset/:employee_id',
  '/mvc/:client/Team/AssetsAndExpenses/Expense/:employee_id',
  '/mvc/:client/Team/DocumentsAndForms/Documents/:employee_id',
  '/mvc/:client/Team/CareerProfile/WorkPosts/:employee_id'
].map(path => router.add([{ path, handler: { type: 'replace', path } }]))

const skip = [
  '',
  '/Employee/OpenEnrollment.aspx',
  '/Employee/gross2Netv2.aspx',
  '/mvc/:client/Team/TimeAndLabor/TimesheetsEdit/:employee_id'
].map(path => router.add([{ path, handler: { type: 'skip' } }]))

router.add([{ path: '/*wildcard', handler: { type: 'wildcard' } }])

export const reconstructEmployeeQuickSearchLink = ({ link, pathname }) => {
  const routes = router.recognize(pathname)
  if (routes && routes.length > 0) {
    const { type, path } = routes[0].handler
    try {
      if (type === 'replace') {
        const linkRoutes = router.recognize(link)
        if (linkRoutes.length > 0) {
          const { client, employee_id } = linkRoutes[0].params
          return path
            .replace(':client', client)
            .replace(':employee_id', employee_id)
        }
      }
    } catch (ex) {
      console.warn('unable to determine contextual employee quick search link')
      console.debug({ link, pathname })
    }
  }
  return link
}
