import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import LeavingToastSVG from '@local/ec-navigation-spa/src/v2/images/leaving-toast.svg'
import './externalRedirectInterstitial.css'

const interstitialNoticeClassName = `
    interstitial-notice fixed bg-white
    p-6 md:p-10 w-screen md:w-auto h-screen md:h-auto flex md:block
    flex-col justify-center items-center md:rounded md:shadow-md`

const privacyPolicy = (
  <a
    href='https://toast.healthsherpa.com/privacy_notice?_agent_id=toast'
    target='_blank'
    rel='noopener noreferrer'
  >
    Privacy Policy
  </a>
)

const termsOfUse = (
  <a
    href='https://toast.healthsherpa.com/terms_of_service?_agent_id=toast'
    target='_blank'
    rel='noopener noreferrer'
  >
    Terms of Use
  </a>
)

const redirectToHealthSherpa = () => {
  window.location.assign('https://toast.healthsherpa.com/?_agent_id=toast')
}

export const ExternalHealthSherpaRedirectInterstitial = () => {
  return ReactDOM.createPortal(
    <div data-nav=''>
      <div className='fixed top-0 left-0 flex justify-center w-screen h-screen bg-gray-0'>
        <div className={interstitialNoticeClassName}>
          <LeavingToastSVG className='interstitial-image' />
          <div className='min-w-full interstitial-text-and-button'>
            <p className='pb-6 pb-10 m-0 type-headline-2 text-default'>
              You are now leaving Toast
            </p>
            <p className='pb-5 type-default text-secondary'>
              You are now leaving the Toast Payroll & Team Management website
              and are being connected to Health Sherpa. We encourage you to read
              Health Sherpa's {privacyPolicy} and/or {termsOfUse}, which may be
              different than ours.
            </p>
            <div className='flex md:justify-end'>
              <Button
                size='base'
                variant='primary'
                className='flex-1 md:flex-none md:w-32'
                onClick={redirectToHealthSherpa}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}
