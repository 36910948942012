const DEFAULT_BREADCRUMB_MENU = []

export default function FinalizeMenus(
  breadcrumbMenu = DEFAULT_BREADCRUMB_MENU,
  applicationMenus,
  contextualMenus
) {
  //This variable is derrived from the current location url and manipulated to only return the relevant link info to allow for matching to applicaiton menu links
  const menuLink = '/' + window.location.href.split('/').splice(3).join('/')
  let finalBreadcrumb
  //If there is no contextual menu we search the application menus for the the selected menu
  if (contextualMenus.menus.length < 1) {
    applicationMenus.find(function ({ isSelected, subMenus = [] }) {
      if (isSelected) {
        subMenus.find(function ({ subMenus = [] }) {
          subMenus.find(function (finalSubMenu) {
            if (
              finalSubMenu.link.toLowerCase() === menuLink.toLowerCase() ||
              finalSubMenu.link.toLowerCase() + '-1' === menuLink.toLowerCase()
            ) {
              finalBreadcrumb = { title: finalSubMenu.title, link: '' }
              finalSubMenu.isSelected = true
            }
          })
        })
      }
    })
  } else {
    contextualMenus.menus.find(function (menu) {
      if (menu.isSelected) {
        menu.subMenus.find(function (subMenu) {
          if (
            subMenu.link.toLowerCase() === menuLink.toLowerCase() ||
            subMenu.link.toLowerCase() === menuLink.toLowerCase() + '-1'
          ) {
            finalBreadcrumb = { title: subMenu.title, link: '' }
            subMenu.isSelected = true
          }
        })
      }
    })
  }
  if (finalBreadcrumb && finalBreadcrumb.title) {
    breadcrumbMenu.push(finalBreadcrumb)
  }
  //This adds the name of the employee right after team if you are using a team context menu
  if (
    breadcrumbMenu.length > 0 &&
    breadcrumbMenu[0].title == 'Team' &&
    contextualMenus.menus[0]
  ) {
    breadcrumbMenu.splice(1, 0, {
      title: contextualMenus.title,
      link: `${contextualMenus.menus[0].link}`
    })
  }

  return { breadcrumbMenu, applicationMenus, contextualMenus }
}
