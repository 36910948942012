import * as React from 'react'
import uniqueId from 'lodash/uniqueId'
import map from 'lodash/map'
import reject from 'lodash/reject'
import every from 'lodash/every'

import { createContext, useContext, useEffect, useState, useMemo } from 'react'

type Value = {
  onMount: (id: string) => void
  onReady: (id: string) => void
  onUnmount: (id: string) => void
}

const DEFAULT_VALUE = {
  onMount: () => {},
  onReady: () => {},
  onUnmount: () => {}
}

const NavReadyContext = createContext<Value>(DEFAULT_VALUE)

const MIN_NAV_WAIT_TIME = 300

const MAX_NAV_WAIT_TIME = 3000

type Props = {
  children: React.ReactNode
}

type ElState = {
  id: string
  isReady: boolean
}

const NavReadyProvider = ({ children }: Props) => {
  const [hasWaited, setHasWaited] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => setHasWaited(true), MIN_NAV_WAIT_TIME)
  }, [])

  const [maxTimeoutReached, setMaxTimeoutReached] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => setMaxTimeoutReached(true), MAX_NAV_WAIT_TIME)
  }, [])

  const [elements, setElements] = useState<ElState[]>([])

  const value = useMemo(() => {
    const onMount = (id: string) => {
      setElements((elements) => [...elements, { id: id, isReady: false }])
    }
    const onReady = (id: string) => {
      setElements((elements) => {
        return map(elements, (el) => {
          return el.id === id ? { ...el, isReady: true } : el
        })
      })
    }
    const onUnmount = (id: string) => {
      setElements((elements) => reject(elements, (el) => el.id === id))
    }

    return { onMount, onReady, onUnmount }
  }, [setElements])

  const canCallCallback =
    maxTimeoutReached || //
    (hasWaited && every(elements, (el) => el.isReady))

  useEffect(() => {
    if (canCallCallback) {
      // some existing pages wait for navigation to be fully loaded,
      // once we receive data from the navigation service

      // @ts-ignore
      window.Toast?.navigationCompleteCallback?.()
    }
  }, [canCallCallback])

  return (
    <NavReadyContext.Provider value={value}>
      {children}
    </NavReadyContext.Provider>
  )
}

const useNavIsReady = ({ isReady }: { isReady: boolean }) => {
  const { onMount, onReady, onUnmount } = useContext(NavReadyContext)

  const [id] = useState(() => uniqueId())

  useEffect(() => {
    onMount(id)
    return () => onUnmount(id)
  }, [onMount, onUnmount, id])

  useEffect(() => {
    if (isReady) onReady(id)
  }, [isReady, onReady, id])
}

export { NavReadyProvider, useNavIsReady }
