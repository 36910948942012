import { makeEcSpaLocalStorageState } from '@toasttab/ec-storage'
import { AccessibleCustomer } from '../../CustomerSwitcher/domain/AccessibleCustomer'

export type CustomerSwitcherStorage = {
  recents: Array<AccessibleCustomer>
  favorites: Array<AccessibleCustomer>
}

type StorageTypes = {
  'customer-switcher': CustomerSwitcherStorage
}

const { useEcSpaLocalStorageState: useEcNavLocalStorageState } =
  makeEcSpaLocalStorageState<StorageTypes>({
    spaName: 'ec-navigation-spa',
    configuration: {
      'customer-switcher': {
        default: {
          recents: [],
          favorites: []
        },
        isValid: (val: any) =>
          val && Array.isArray(val.recents) && Array.isArray(val.favorites)
      }
    }
  })

export { useEcNavLocalStorageState }
