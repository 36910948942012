import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { ExternalMineralRedirectInterstitial } from './v2/components/interstitial/ExternalMineralRedirectInterstitial'
import { ExternalHealthSherpaRedirectInterstitial } from './v2/components/interstitial/ExternalHealthSherpaRedirectInterstitial'
import { ThemeService } from './v2/services/index'
import Logo from './v2/images/toast-logo-light.svg'
import { AppErrorBoundary } from '@local/ec-app'
import { useFeature, useNavigationVersion } from '@toasttab/ec-features'

const ErrorFallback = () => (
  <div className='px-6 pt-0.5 bg-gray-125'>
    <Logo
      xlinkTitle='Toast Payroll and Team Management Logo'
      role='img'
      data-testid='logo-image'
      style={{ height: '26px', marginTop: '13px', marginBottom: '9px' }}
    />
  </div>
)

const Navigation = {
  V2: React.lazy(() => import('./v2/V2App')),
  V3: React.lazy(() => import('./v3/V3App'))
}

const BaseAppInner = () => {
  const hasV3Available = useFeature('ec-platform-use-navigation-v3')
  const { version } = useNavigationVersion()

  if (version === 'v3' && hasV3Available) {
    return <Navigation.V3 />
  }
  return <Navigation.V2 />
}

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <AppErrorBoundary application='navigation' fallback={<ErrorFallback />}>
        <BaseAppInner />
      </AppErrorBoundary>
    )
  },
  {
    path: '/leavingToast',
    element: <ExternalMineralRedirectInterstitial />
  },
  {
    path: '/leavingToastHealthSherpa',
    element: <ExternalHealthSherpaRedirectInterstitial />
  }
])

const BaseAppOuter = () => {
  return (
    <ThemeService>
      <RouterProvider router={router} />
    </ThemeService>
  )
}

export { BaseAppOuter as BaseApp }
