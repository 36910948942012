import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import LeavingToastSVG from '../../images/leaving-toast.svg'
import './externalRedirectInterstitial.css'

const interstitialNoticeClassName = `
    interstitial-notice fixed bg-white
    p-6 md:p-10 w-screen md:w-auto h-screen md:h-auto flex md:block 
    flex-col justify-center items-center md:rounded md:shadow-md`

const privacyPolicy = (
  <a
    href='https://trustmineral.com/privacy-policy/'
    target='_blank'
    rel='noopener noreferrer'
  >
    Privacy Policy
  </a>
)
const termsOfUseLink = (
  <a
    href='https://trustmineral.com/terms-of-service/'
    target='_blank'
    rel='noopener noreferrer'
  >
    Terms of Use
  </a>
)

const redirectToMineralHR = () => {
  window.location.assign(
    'https://toast.myhrsupportcenter.com/users/sign_in?redirect_to=L2FwcC9sYW5kaW5nLzM2L3Nob3cvbGlzdA%3D%3D'
  )
}

export const ExternalMineralRedirectInterstitial = () => {
  return ReactDOM.createPortal(
    <div data-nav=''>
      <div className='fixed top-0 left-0 flex justify-center w-screen h-screen bg-gray-0'>
        <div className={interstitialNoticeClassName}>
          <LeavingToastSVG className='interstitial-image' />

          <div className='min-w-full interstitial-text-and-button'>
            <p className='pt-10 pb-6 m-0 type-headline-2 text-default'>
              You are now leaving Toast
            </p>
            <p className='pb-5 type-default text-secondary'>
              You are now being redirected from Toast Payroll + Team Management
              to Mineral HR. The information provided by Mineral HR is strictly
              for informational purposes and should not be construed as legal
              advice. See Mineral’s {privacyPolicy} and {termsOfUseLink} for
              additional information.
            </p>
            <div className='flex md:justify-end'>
              <Button
                size='base'
                variant='primary'
                className='flex-1 md:flex-none md:w-32'
                onClick={redirectToMineralHR}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}
