import { useQuery } from '@tanstack/react-query'
import { apiCall } from '../helpers'

// https://payroll.preprod.eng.toasttab.com/hrpillarautomationnation/employees/ddf2e86d-2d5a-11ee-ae36-1274e2703f11/profile

const SECONDS = 1000

const MINUTES = 60 * SECONDS

type Resp = {
  personalProfile?: {
    basic?: {
      firstName?: string
      lastName?: string
    }
  }
}

type Args = {
  companyCode: string | undefined
  employeeUuid: string | undefined
}

const useEmployeeInfoQuery = (args: Args) => {
  const { companyCode, employeeUuid } = args

  return useQuery({
    queryKey: ['employees', employeeUuid],
    queryFn: async () => {
      const resp = await apiCall({
        method: 'GET',
        url: `/${companyCode}/employees/${employeeUuid}/profile`,
        headers: {
          Accept: 'application/com.toasttab.payroll.v1+json'
        }
      })

      return resp as Resp
    },

    staleTime: 10 * MINUTES,

    cacheTime: 20 * MINUTES,

    enabled: !!employeeUuid
  })
}

export default useEmployeeInfoQuery
