import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { NavigationRoot } from '@local/ec-navigation-spa'
import { domElementGetter } from '@toasttab/ec-layout'
import './index.css'
import {setupSentry} from "@toasttab/ec-sentry";

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-nav',
  rootComponent: NavigationRoot,
  domElementGetter: domElementGetter.navigation,
  portalContainers: ['banquetPortalsContainer', 'main-navigation']
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-navigation-spa'

setupSentry({
  spa: name,
  dsn: 'https://047637324ff54321b4446587d878fc44@o37442.ingest.us.sentry.io/4505602209939456'
})
