import { matchPath } from 'react-router-dom'
import some from 'lodash/some'

type UrlArg = string[] | string | undefined | null

const hasRouteMatch = (arg: UrlArg, pathname: string) => {
  if (!pathname) return false
  if (!arg) return false
  if (typeof arg === 'string') {
    return !!matchPath(arg, pathname)
  }
  if (typeof Array.isArray(arg)) {
    return some(arg, (url) => matchPath(url, pathname))
  }
  return false
}

export default hasRouteMatch
