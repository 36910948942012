import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { NavigationServiceContext } from './NavigationService'
import FinalizeMenus from './FinalizeMenus'

export const StaticNavigationService = ({ menus, links, data, children }) => {
  const value = useMemo(() => {
    const { breadcrumbMenu, applicationMenus, contextualMenus } = FinalizeMenus(
      data.breadcrumbs,
      data.applicationMenus,
      data.contextualMenus
    )
    return {
      menus,
      applicationMenus: applicationMenus,
      contextualMenus: contextualMenus,
      breadcrumbs: breadcrumbMenu,
      isReady: true,
      links,
      jumpToMenus: data.jumpToMenus
    }
  }, [menus, links, data])

  useEffect(() => {
    if (window.Toast && window.Toast.navigationCompleteCallback) {
      window.Toast.navigationCompleteCallback()
    }
  }, [])

  return (
    <NavigationServiceContext.Provider value={value}>
      {children}
    </NavigationServiceContext.Provider>
  )
}
