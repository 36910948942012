import * as React from 'react'

/**
 * A hook to apply a CSS class to either the body or html tag
 *
 * This hook is designed to add / remove the specified CSS class
 * when the hook is executed / cleaned up.
 *
 * Good usages for this hook are in conjunction with more specific
 * CSS selectors or concepts like scroll locking.
 *
 * @param scopeClassName the class to apply
 * @param tag the element that the specified class will be added to
 */
export function useCssScope(
  scopeClassName: string,
  tag: 'body' | 'html' = 'body'
) {
  React.useEffect(() => {
    const element = document.getElementsByTagName(tag).item(0)

    if (element) {
      element.classList.add(scopeClassName)

      return () => element.classList.remove(scopeClassName)
    } else {
      console.warn(`unable to find tag: ${tag} to apply css scope to`)
    }
  }, [scopeClassName, tag])
}
