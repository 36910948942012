const getPathnameFromUrl = (url: string) => {
  try {
    if (!url) return url
    return url.split('?')[0] || url
  } catch {
    return url
  }
}

export { getPathnameFromUrl }
