import { ToastUser } from '@toasttab/ec-banquet-props'

export * from './src/AppContainer'
export * from './src/AppErrorBoundary'

export type UserType = 'toast_internal' | 'employee'
export type StaticRoleName =
  | '_SuperAdmin'
  | '_SuperAdminLight'
  | '_Customer'
  | 'HR_Executive'
  | 'Payroll'
  | 'HR_Plus'
  | 'Manager'
  | 'Employee'
  | 'New_Hire'
  | 'Fired'
  | 'Applicant'

export const hasAnyRole = (
  user: ToastUser,
  requiredRole: Array<StaticRoleName>
) => {
  if (user.staticRoleNames === null) {
    return false
  }
  const roles = user.staticRoleNames.split(',') as Array<StaticRoleName>

  return roles.some((grant: StaticRoleName) => requiredRole.includes(grant))
}

export interface ToastEnvironment {
  user: ToastUser
}

export interface NavigationMenuItem {
  title: string
  link: string
}

export interface GlobalLinks {
  advancedSearch: string
}

export interface GlobalMenus {
  account: NavigationMenuItem
  contact: NavigationMenuItem
  documentCourier: NavigationMenuItem
  help: NavigationMenuItem
  session: NavigationMenuItem
}

export interface CoreConfig {
  user: ToastUser
  menus: GlobalMenus
  navigationUrl?: string
  links: GlobalLinks
  launchDarklyClientKey: string
  pendoClientKey: string
  navigationData?: any
  auth0BaseUrl: string
  auth0ClientId: string
  payrollLoginCookieDomain: string
  refreshConfig: () => CoreConfig
  isContextMenuBarEnabled?: boolean
}

export type ConfiguredWindow = Window & { Toast: CoreConfig }
